import * as React from 'react'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import { Field } from 'formik'
import joinClasses from '/src/functions/joinClasses'
import './styles.scss'

const Error = loadable(() => import('/src/components/Form/Error'))
const Required = loadable(() =>
    import('/src/components/Form/Required/Required')
)

const TextField = ({
    label,
    description,
    name,
    type,
    format,
    placeholder,
    fieldRef,
    isSubmitting,
    isSubmitted,
    required,
    className
}) => {
    const classes = ['c-textfield', ...(className ? [className] : [])]

    const concatenatedClasses = joinClasses(classes)

    return (
        <div className={concatenatedClasses}>
            {label && (
                <label htmlFor={name}>
                    {label}
                    {required && <Required />}
                </label>
            )}

            {description && (
                <div className="c-textfield__description">{description}</div>
            )}

            <Field
                type={type ? type : 'text'}
                inputMode={format === 'number' ? 'numeric' : 'text'}
                pattern={format === 'number' ? '[0-9 ]+' : '.*'}
                name={name}
                id={name}
                placeholder={placeholder}
                innerRef={fieldRef}
                disabled={isSubmitting || isSubmitted}
                className={'form-control'}
            />

            <Error name={name} />
        </div>
    )
}

TextField.propTypes = {
    /**
     * Field label
     */
    label: PropTypes.string,
    /**
     * Field description
     */
    description: PropTypes.string,
    /**
     * Field name
     */
    name: PropTypes.string.isRequired,
    /**
     * Field type
     */
    type: PropTypes.oneOf(['email', 'search', 'hidden']),
    /**
     * Format
     */
    format: PropTypes.oneOf(['number']),
    /**
     * Field placeholder
     */
    placeholder: PropTypes.string,
    /**
     * Optional field ref
     */
    fieldRef: PropTypes.object,
    /**
     * Whether form is submitting
     */
    isSubmitting: PropTypes.bool.isRequired,
    /**
     * Whether form is submitted
     */
    isSubmitted: PropTypes.bool.isRequired,
    /**
     * Whether field is required
     */
    required: PropTypes.bool,
    /**
     * Optional CSS classes
     */
    className: PropTypes.string
}

TextField.defaultProps = {
    name: 'firstname',
    isSubmitting: false,
    isSubmitted: false,
    required: false
}

export default TextField
